<template>
  <section class="Auth_root">
    <div class="max-w-[880px] py-[100px] px-[10px] mx-auto xl:py-[100px] px-0">
      <img class="h-[55px] m-auto md:h-auto" src="@/assets/img/auth/auth-logo.svg" alt="" />
      <div class="flex justify-between items-center w-full border-b-[1px] border-[#2af3f3]/[0.1] pt-[20px] pb-[10px] mb-[100px] md:py-[20px] md:mt-[65px] md:mb-[44px]">
        <RoundButton
          withIcon
          iconType="arrowLeft"
          text="back to main page"
          @click="handleGoMainPage"
        />
        <transition name="slide-fade" mode="out-in">
          <h1 class="m-0 p-0 font-medium text-[16px] leading-[19px] text-center uppercase text-white md:text-[21px] md:leading-[25px]">{{ authCurrentRoute }}</h1>
        </transition>
        <span class="w-[156px] text-left hidden md:block"></span>
      </div>
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';

export default {
  components: {
    RoundButton,
  },
  computed: {
    authCurrentRoute() {
      switch (this.$route.name) {
        case 'login':
          return 'login';
        case 'loginVerify':
          return 'Two-factor authentication';
        case 'registration':
          return 'registration';
        case 'registration.email.verification':
          return 'registration';
        case 'success':
          return 'registration';
        case 'resend':
          return 'registration';
        case 'reset':
          return 'reset password';
        case 'resetConfirm':
          return 'reset password';
        default:
          return 'authorization';
      }
    },
  },
  methods: {
    ...mapActions('user', ['logoutAction']),
    handleGoMainPage() {
      // this.$router.push({ name: 'landing' });
      // setTimeout(() => {this.logoutAction()}, 1000);
      this.logoutAction()
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.Auth_root {
  width: 100%;
  min-height: 100vh;
  background: #0f1f3f;
  background-image: url('../../../assets/img/auth/auth_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  overflow: auto;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
